<template>
  <v-dialog max-width="900" v-model="showLocationEditionModal">
    <template>
      <v-form @submit.prevent="saveLocation">
        <v-card rounded="lg">
          <v-card-title class="d-flex justify-space-between align-center">
            <h4 class="font-weight-medium ps-2">{{ title }}</h4>
          </v-card-title>

          <v-divider class="mb-4"></v-divider>

          <v-card-text>
            <v-text-field
              :disabled="!!editingRow.gid"
              label="cod_id"
              v-model="editingRow.cod_id"
            ></v-text-field>

            <select-point-on-map
              :key="editingRowKey"
              :zoom.sync="editingRow.pointZoom"
              :point.sync="editingRow.point"
              :initialPosition="{
                zoom: 6,
                longitude: this.$store.getters.getCompanyCapitalCoordinates[0], 
                latitude: this.$store.getters.getCompanyCapitalCoordinates[1],
              }"
            />
          </v-card-text>

          <v-card-actions class="my-2 d-flex justify-end">
            <v-btn type="submit" color="primary" :loading="editingRowLoading">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
  </v-dialog>
</template>

<script>
import EntidadesBdgdService from '@/services/EntidadesBdgdService';

export default {
  components: {
    SelectPointOnMap: () => import('@/components/general/SelectPointOnMap.vue'),
  },

  props: {
    item: {
      type: Object,
      required: false,
    }
  },

  data: () => ({
    showLocationEditionModal: false,
    editingRowLoading: false,
    editingRowKey: 1,
    editingRow: {},
  }),

  watch: {
    item(newValue, currentValue) {
      if (!newValue) {
        this.showLocationEditionModal = false;
        return;
      }

      this.openModal();
    }
  },

  computed: {
    title() {
      return `${this.item?.gid ? 'Alterar' : 'Adicionar nova'} ${this.$route.params.entidade.toUpperCase()}`;
    }
  },

  methods: {
    openModal() {
      if (this.item.gid) {
        this.editingRow = {
          ...this.item,
          point: [this.item.longitude, this.item.latitude],
          pointZoom: 18,
        };
      } else {
        this.editingRow = {};
      }

      this.editingRowKey = this.editingRowKey + 1;
      this.showLocationEditionModal = true;
    },

    closeModal(save = false) {
      this.showLocationEditionModal = false;
      this.$emit('update:item', null);
      this.editingRow = {};
    },

    saveLocation() {
      if (!this.editingRow.cod_id || !this.editingRow.point?.length) {
        this.$toast.error('Preencha todos os dados', '', {
          position: 'topRight'
        });
        return;
      }

      this.editingRowLoading = true;
      const data = {
        bdgd_versao_id: this.$route.params.bdgdVersaoId,
        entidade: this.$route.params.entidade,
        gid: this.item.gid,
        cod_id: this.editingRow.cod_id,
        values: this.editingRow.point,
        tipoEntidade: 'ponto'
      };
      EntidadesBdgdService.salvarDadoLatitudeLongitude(data)
      .then(() => {
        this.closeModal(true);
      })
      .catch((err) => {
        this.$toast.error(err?.data?.error || 'Falha ao salvar localização', '', {
            position: 'topRight'
        })
       })
      .finally(() => {
        this.editingRowLoading = false;
      });
    },
  }
};
</script>
